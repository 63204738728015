@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@400;600&display=swap);
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	--textColor: rgb(48, 50, 53);
	--textColorClearer: #92959e;
	--maxWidth: 1170px;
	--maxWidthSections: 920px;
	--maxWidthArticle: 820px;
	--mainColor: #01425e;
	--mainColorClearer: #03638d;
	--mainColorClearest: #3ea7d6;
	--mainColorLight: #03648d5b;
	--secondColor: rgb(9, 190, 139);
	--lightGreen: rgba(12, 238, 174, 0.301);
	--secondColorDark: rgb(0, 167, 123);
	--secondColorDarker: rgb(7, 73, 55);
	--errorColor: #ff5666;
	--validColor: #29f5bb;
	--lightGrey: rgba(165, 165, 165, 0.363);
	--fossilsGrey: #2b2b2b;
	--legendcolor: #a9a9a9;
	--lightGreyBackground: rgba(240, 240, 240, 0.504);
	--transitionBasic: all 0.3s ease-in-out;
	--transitionQuick: all 0.15s ease-in-out;
	--columnSidePadding: 4rem;
	--regularHomePadding: 4rem;
	--centralMarginHome: 9rem;
	--standardTextSize: 1.7rem;
}

html {
	font-size: 62.5%;
}

body {
	font-family: 'Open Sans', sans-serif;
	color: rgb(48, 50, 53);
	color: var(--textColor);
}

a {
	color: #03638d;
	color: var(--mainColorClearer);
	cursor: pointer;
}

a:hover {
	color: #3ea7d6;
	color: var(--mainColorClearest);
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4 {
	font-family: 'Poppins';
}

h3,
h4 {
	margin-bottom: 2.5rem;
}

/********************
    LOGIN / SIGNUP
********************/

.login-body:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background: linear-gradient(to right top, rgba(3, 100, 141, 0.1), rgb(9, 190, 139, 0.5)), url('https://images.unsplash.com/photo-1490100667990-4fced8021649?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80');
}

.login-wrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.login-form {
	width: 350px;
}
.login-form .form__header {
	position: relative;
	width: 100%;
	display: -webkit-flex;
	display: flex;
	padding: 2rem 0 0 0;
	background-color: #323a45;
	border-radius: 5px 5px 0 0;
}

.login-form .header__logo {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	background: #fff;
}

.login-form .form__header svg {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 35px;
	height: 35px;
}
.login-form .form__header .triangle {
	position: absolute;
	left: 47.5%;
	bottom: -9%;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid #323a45;
}

.login-form .form__body {
	height: calc(100% - 80px);
	background-color: #fff;
	text-align: center;
	border-radius: 0 0 5px 5px;
}
.login-form .form__body .title {
	padding-bottom: 2rem;
	padding-top: 2rem;
	margin: 0;
	font-size: 2.2rem;
	line-height: 3.1rem;
	font-weight: 600;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: center;
}

.login-form .form__body .title img {
	margin-right: 1.3rem;
}

.login-form .form__body .title span {
}
.login-form .form__body .description {
	font-size: 12px;
}
.login-form .form__body input {
	color: #01425e;
	width: 250px;
	padding: 8px;
	margin: 7px;
	border: 1px solid #cccdd1;
	border-radius: 5px;
	outline: none;
}

.login-form .form__body input::-webkit-input-placeholder {
	font-size: 12px;
	letter-spacing: 1.5px;
	opacity: 0.8;
}
.login-form .form__body input:-ms-input-placeholder {
	font-size: 12px;
	letter-spacing: 1.5px;
	opacity: 0.8;
}
.login-form .form__body input::placeholder {
	font-size: 12px;
	letter-spacing: 1.5px;
	opacity: 0.8;
}
.login-form .form__body input:focus::-webkit-input-placeholder {
	opacity: 0.3;
}
.login-form .form__body input:focus:-ms-input-placeholder {
	opacity: 0.3;
}
.login-form .form__body input:focus::placeholder {
	opacity: 0.3;
}
.login-form .form__body input[type='submit'] {
	width: 250px;
	padding: 14px;
	margin-top: 10px;
	background: rgb(9, 190, 139);
	color: #fff;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s;
}

.login-form .form__body button:hover {
	background: #01425e;
}

.login-form .form__body .disclaimer {
	margin: 2rem 5rem;
	font-size: 1.1rem;
	opacity: 0.5;
	text-align: left;
}
.login-form .form__body .disclaimer span {
	border-bottom: 1px solid #cccdd1;
}

.login,
.social {
	text-align: center;
}

.login {
	margin-top: 1rem;
	font-size: 1.2rem;
	color: #01425e;
	color: var(--mainColor);
}
.login a {
	color: #29f5bb;
	color: var(--validColor);

	font-size: 1.3rem;
}

.login a:hover {
	color: #29f5bb;
	color: var(--validColor);
}

.social i {
	padding: 3px;
	color: #9698a1;
}

.header__logo .nav-logo {
	height: 3.2rem;
}

.or-class {
	font-family: 'Poppins';
	font-size: 1.2rem;
	margin: 3rem auto;
	border-bottom: 1px solid #cccdd1;
	position: relative;
}

.or-panel {
	position: absolute;
	background: #fff;
	padding: 0 0.5rem;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

/* -------------
 * NAV BAR
 * ------------- */

.link-container .login-btn {
	font-size: 1.6rem;
	font-weight: 400;
	padding: 0.5rem 1rem;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.profile-link {
	color: #fff;
	font-size: 1.6rem;
	cursor: pointer;
	position: relative;
	display: -webkit-flex;
	display: flex;
	padding: 0.5rem 0;
}

.profile-link:hover .logout-panel {
	display: block;
}

.logout-panel:hover {
	display: block;
}

.logout-panel a {
	color: rgb(48, 50, 53);
	color: var(--textColor);
}

.logout-panel a {
	text-transform: none !important;
	letter-spacing: 0px !important;
	color: rgb(48, 50, 53) !important;
	color: var(--textColor) !important;
	text-decoration: none !important;
}

.logout-panel a:hover {
	text-decoration: underline !important;
}

.logout-panel {
	display: none;
	left: -1rem;
	top: 3.4rem;
	border-radius: 5px;
	position: absolute;
	width: 18rem;
	padding: 2rem;
	z-index: 10;
	border: 1px solid grey;
	background: #fff;
	color: rgb(48, 50, 53);
	color: var(--textColor);
	transition: all O.3s;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.logout-panel:hover {
}

.navbar {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	background: #01425e;
	background: var(--mainColor);
	-webkit-justify-content: center;
	        justify-content: center;
}

.navbar-faded-out {
	opacity: 0;
	transition: opacity none;
}

.navbar-faded-in {
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

nav #marker {
	position: absolute;
	height: 0.3rem;
	width: 100%;
	background: #fff;
	bottom: 0rem;
	transition: 0.4s;
	border-radius: 0.4rem;
	left: 0;
	opacity: 0;
}

.nav-header {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	max-width: 1170px;
	max-width: var(--maxWidth);
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	padding: 2rem;
}

.nav-header > div,
.nav-header > a {
	border-radius: 0;
}

.nav-header-open .nav-links {
	visibility: visible;
	-webkit-animation-name: enter-nav-panel;
	        animation-name: enter-nav-panel;
	-webkit-animation-duration: 0.5s;
	        animation-duration: 0.5s;
	-webkit-animation-timing-function: ease-out;
	        animation-timing-function: ease-out;
}

@-webkit-keyframes enter-nav-panel {
	from {
		-webkit-transform: translateY(-100vh);
		        transform: translateY(-100vh);
	}

	to {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes enter-nav-panel {
	from {
		-webkit-transform: translateY(-100vh);
		        transform: translateY(-100vh);
	}

	to {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.nav-header > a:first-child {
	margin-left: 4rem;
	margin-left: var(--regularHomePadding);
}

.nav-header > div:last-child,
.nav-header > .nav-links {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	margin-right: 4rem;
	margin-right: var(--regularHomePadding);
}

.nav-header > div {
	position: relative;
}

.nav-header > div:last-child a,
.nav-header > .nav-links a {
	color: white;
	font-size: 1.65rem;
	display: inline-block;

	font-weight: 400;
}

.nav-header > div:last-child div {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	height: 100%;
}

.nav-header > .nav-links .link-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.nav-header > div:last-child div:not(:last-child),
.nav-header > .nav-links div:not(:last-child) {
	margin-right: 4rem;
	margin-right: var(--regularHomePadding);
}

.nav-logo {
	max-height: 4.3rem;
}

.nav-logo-white {
	max-height: 4.3rem;
}

.nav-hero {
	position: absolute;
	top: 0;
	z-index: 5;
	/* background : linear-gradient(rgb(30, 30, 30,0.55) 0%, rgb(30, 30, 30,0.55) 75%, rgb(30, 30, 30,0) 100%); */
	background: transparent;
}

.navbar .nav-header .burgermenu {
	max-height: 45px;
	display: none;
}

.nav-header-open .nav-links {
	position: fixed;
	background: #01425e;
	background: var(--mainColor);
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 10;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.nav-header-open .nav-links > div {
	margin-top: 20rem;
}

.nav-header-open div,
.nav-header-open a {
	z-index: 11;
}

.nav-header .site-header {
	font-weight: 600;
	font-size: 2.3rem;
}

.nav-header-open .nav-links a,
.site-header {
	color: white;
	font-size: 1.6rem;
	display: inline-block;
	border-radius: 0;
}

.nav-header-open .nav-links a {
	text-transform: uppercase;
	letter-spacing: 1px;
}

.nav-header-open .link-container {
	position: relative;
	display: inline-block;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding: 1rem 0;
}

.nav-header-open #marker {
	bottom: 0;
}

.nav-header-open .link-container > div:not(:last-child) {
	margin-right: 4rem;
	margin-right: var(--regularHomePadding);
}

.menu-btn-burger {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 35px;
	cursor: pointer;
}

.menu-btn-burger > div {
	background: #fff;
	max-height: 4px;
	border-radius: 8px;
	width: 100%;
	margin: 3px auto;
	transition: all 0.2s ease-in-out;
}

.menu-open {
	-webkit-transform: translateX(10px);
	        transform: translateX(10px);
}

.menu-open > div:first-child {
	-webkit-transform: rotate(45deg) translateY(14px);
	        transform: rotate(45deg) translateY(14px);
}

.menu-open > div:last-child {
	-webkit-transform: rotate(-45deg) translateY(-14px);
	        transform: rotate(-45deg) translateY(-14px);
}

.menu-btn-burger > div:nth-child(2) {
	transition: opacity 0.1s ease-in-out 0.1s;
}

.menu-open > div:nth-child(2) {
	opacity: 0;
	transition: opacity 0.1s;
}

.navbar-fixed {
	position: fixed;
	z-index: 10;
}

/* ----------------------------
 * HOME PAGE
 * ---------------------------- */

.prez-container {
	width: 100%;
	padding: calc(2 * 4rem) 0;
	padding: calc(2 * var(--regularHomePadding)) 0;
}

.prez {
	max-width: 920px;
	max-width: var(--maxWidthSections);
	width: 100%;
	padding: 0 calc(2 * 4rem);
	padding: 0 calc(2 * var(--regularHomePadding));
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}

.test-header {
	max-width: 600px;
	margin: calc(2 * 4rem) calc(4 * 4rem);
	margin: calc(2 * var(--regularHomePadding)) calc(4 * var(--regularHomePadding));
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
	padding: 4rem;
	border-radius: 10px;
}

.prez h1 {
	font-size: 3.4rem;
}

.prez p {
	font-size: 1.8rem;
	text-align: center;
	max-width: 52rem;
	margin: 0 auto;
	margin-bottom: calc(4rem * 2);
	margin-bottom: calc(var(--regularHomePadding) * 2);
	margin-top: calc(4rem / 2);
	margin-top: calc(var(--regularHomePadding) / 2);
}

.prez-curriculums {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: stretch;
	        align-items: stretch;
}

.prez-curriculums > div {
	width: 45rem;
	margin-bottom: 4rem;
}

.prez-curriculums > div:not(:last-child) {
	margin-right: calc(4rem);
	margin-right: calc(var(--regularHomePadding));
}

.prez-curriculums > div > div {
}

/* -------------
 * FOOTER
 * ------------- */

.footer {
	color: white;
	font-size: 1.3rem;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	height: 80px;
	background: #01425e;
	background: var(--mainColor);
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: stretch;
	        align-items: stretch;
}

/* -------------
 * DETAILS SECTION
 * ------------- */

.details-section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	max-width: 920px;
	max-width: var(--maxWidthSections);
	margin: 0 4rem;
	margin: 0 var(--columnSidePadding);
}

.details-text {
	padding: 0 4rem;
	padding: 0 var(--regularHomePadding);
}
.system-text,
.home-quizz,
.details-section p {
	font-size: 1.8rem;
	line-height: 1.5;
}

.details-section p {
	margin-bottom: 2rem;
}

.details-img {
	max-height: 100%;
	height: 400px;
}

.details-img > img {
	max-height: 100%;
}

.details-section-container {
	height: 670px;
	width: 100%;
}

.subtitle {
	font-family: 'Open Sans';
	color: rgb(48, 50, 53);
	color: var(--textColor);
	font-size: 1.6rem;
	line-height: 3rem;
	opacity: 0.6;
	margin-bottom: 0.5rem;
}

.title {
	font-size: 3.4rem;
	margin-bottom: 4rem;
}

/* -------------
 * SYSTEM SECTION
 * ------------- */

.system-container {
	background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(/static/media/nasa.7741b007.jpg);
	height: 40rem;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}

.system > h1 {
	font-family: 'Open Sans';
	color: #fff;
	display: inline-block;
	max-width: 480px;
	line-height: 1.5;
	font-size: 4.5rem;
	text-align: center;
	font-family: 'Poppins';
	font-size: 4.5rem;
	line-height: 5.4rem;
}

.system > h1.title {
	margin-bottom: 0;
}

.system-text {
	background: rgba(240, 240, 240, 0.504);
	background: var(--lightGreyBackground);
	padding: 4rem 0;
}

.system-text h6 {
	font-family: 'Open Sans';
	font-size: 2.5rem;
	color: #01425e;
	color: var(--mainColor);
}

.benefit-title {
	line-height: 1.2;
	margin-bottom: 2rem;
}

.benefit-container {
	max-width: 920px;
	max-width: var(--maxWidthSections);
	padding: 4rem;
	padding: var(--columnSidePadding);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.benefit {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	width: 50%;
	padding: 2rem;
	margin: 2rem 0;
}

.benefit > div:last-child {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.benefit > .icon {
	font-size: 4rem;
	padding: 0 2rem;
}

.benefit > .text {
	padding: 1rem 0rem;
}

/* -------------
 * HERO SECTION
 * ------------- */

.hero {
	height: 100vh;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background: linear-gradient(to right top, rgba(3, 100, 141, 0.7), rgb(9, 190, 139, 0.5)), url('https://images.unsplash.com/photo-1490100667990-4fced8021649?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80');
}

@supports (-webkit-text-stroke: 1px black) {
	hero-title {
		-webkit-text-stroke: 1px black;
		-webkit-text-fill-color: white;
	}
}

.hero-title {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 2;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	width: 100%;
	max-width: 1170px;
	max-width: var(--maxWidth);
	height: 100vh;
}

.hero-text-container {
	position: absolute;
	top: 50%;
	left: 0;
	padding: 4rem;
	padding: var(--regularHomePadding);
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	z-index: 15;
	/* background: radial-gradient(closest-side, rgb(30, 30, 30, 0.2) 0%, rgb(0, 0, 0,  0.3) 65%, rgb(30, 30, 30,  0.0) 100%); */
	min-width: 64rem;
}

.hero-text-container h2 {
	font-size: 4.5rem;
	color: #fff;
	font-weight: bold;
}

.hero-text-container h1 {
	font-family: 'Open Sans';
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #fff;
	margin-bottom: 0.8rem;
}

/* -------------
 * BUTTONS
 * ------------- */

.hero-btn-container {
	margin-top: 4rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-content: stretch;
	        align-content: stretch;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.btn {
	padding: 1rem 2.4rem;
	color: #fff;
	border-radius: 8px;
	font-size: 1.6rem;
	letter-spacing: 1px;
	font-weight: 600;
	cursor: pointer;
	text-align: center;
}

.u-center .btn > a,
.u-center .btn > a:hover {
	color: #fff;
}

.btn:not(:last-child) {
	margin-right: 2rem;
}

.dark-blue-btn {
	background: #01425e;
	background: var(--mainColor);
	border: none;
	transition: all 0.3s ease-in-out;
	transition: var(--transitionBasic);
}

.dark-blue-btn:hover {
	background: #03638d;
	background: var(--mainColorClearer);
}

.green-btn {
	background: rgb(9, 190, 139);
	background: var(--secondColor);
	border: none;
	transition: all 0.3s ease-in-out;
	transition: var(--transitionBasic);
}

.green-btn:hover {
	background: rgb(0, 167, 123);
	background: var(--secondColorDark);
}

.ghost-btn {
	background: rgb(0, 0, 0, 0);
	border: 2px solid #fff;
	transition: all 0.3s ease-in-out;
	transition: var(--transitionBasic);
}

.ghost-btn:hover {
	background: rgb(9, 190, 139);
	background: var(--secondColor);
	border: 2px solid rgb(9, 190, 139);
	border: 2px solid var(--secondColor);
}

.blue-btn {
	background: #01425e;
	background: var(--mainColor);
	border: none;
	transition: all 0.3s ease-in-out;
	transition: var(--transitionBasic);
}

.blue-btn:hover {
	background: #03638d;
	background: var(--mainColorClearer);
}

/* ----------------------------
 * QUIZZ SECTION
 * ---------------------------- */

.home-quizz-container {
	padding: calc(2 * 4rem) calc(2 * 4rem);
	padding: calc(2 * var(--regularHomePadding)) calc(2 * var(--regularHomePadding));
	background: #fff;
}

.home-quizz {
	max-width: 920px;
	max-width: var(--maxWidthSections);
	width: 100%;
	padding: 0 calc(4rem * 2);
	padding: 0 calc(var(--regularHomePadding) * 2);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: stretch;
	        align-items: stretch;
}

.home-quizz h1 {
	font-size: 2.4rem;
	color: #333333;
	margin-bottom: 2.5rem;
}

.home-quizz p {
	margin-bottom: 2.5rem;
}

.home-quizz > div:first-child {
	max-height: 100%;
	height: 100%;
	width: 50%;
	margin-right: 4rem;
	margin-right: var(--regularHomePadding);
}

.home-quizz img {
	max-height: 100%;
	width: 100%;
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
}

.home-quizz-text {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: baseline;
	        align-items: baseline;
	width: 50%;
}

/* ----------------------------
 * CURRICULUM THUMBS
 * ---------------------------- */

.cur-container {
	position: relative;
	border-radius: 8px;
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
}

.cur-image {
	border-radius: 8px 8px 0 0;
	display: -webkit-flex;
	display: flex;
	padding: 4rem 2rem 2rem 2rem;
	background: #fff;
}

.cur-image > img {
	width: 100%;
	max-height: 6.5rem;
	height: auto;
	margin: auto;
	border-radius: 8px 8px 0 0;
}

.cur-description {
	padding: 2rem;
	min-height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	background: #fff;

	border-radius: 8px;
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
}

.cur-container .cur-description h1 {
	font-size: 3.4rem;
	margin-bottom: 2rem;
	opacity: 1;
	text-align: center;
	margin: 2rem auto;
	padding: 2rem;
}

.cur-container .cur-description h1 a {
	color: rgb(48, 50, 53);
	color: var(--textColor);
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
}

.cur-container .cur-description h1 a:hover {
	color: #03638d;
	color: var(--mainColorClearer);
}

.cur-container .cur-description p {
	font-size: 1.6rem;
	line-height: 1.5;
	text-align: left;
	margin: 0 0 2rem 0;
}

.timing {
	background: #e7e7e7;
	padding: 3px;
	border-radius: 8px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.description-footer {
	margin-top: 2rem;
	font-size: 1.4rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: calc(100% - 2rem);
}

.description-footer > div {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.footer-article {
	border-radius: 10px;
	padding: 0.3rem 0rem 0.3rem 0.3rem;
	font-size: 1.4rem !important;
}

.footer-article > div {
	font-size: 1.4rem !important;
}

.cur-description-icon {
	font-size: 2.4rem;
}

.cur-icon-text {
	margin-left: 0.8rem;
	margin-right: 2rem;
	line-height: 2.4rem;
}

.curr-thumb-description {
	width: 70%;
	padding: 2rem 4rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.simulation-section {
	background: linear-gradient(to right top, rgba(3, 100, 141, 0.2), rgb(9, 190, 139, 0.5)), url('https://images.unsplash.com/photo-1418065460487-3e41a6c84dc5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80');

	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}

/*--------------
 * COOKIE CONSENT
 * -------------*/

.ecorg-cookie-panel {
	z-index: 50;
	bottom: 2rem;
	right: 2rem;
	padding: 1rem;
	width: 450px;
	max-width: 90%;
	height: auto;
	overflow: auto;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: row;
	        flex-direction: row;
	background: #fff;
	position: fixed;
	border-radius: 10px;
	font-size: 1.5rem;
	box-shadow: 2px 2px 5px #333;
}

.ecorg-cookie-panel div {
}

.ecorg-cookie-panel > div p {
	margin-bottom: 1rem;
	font-size: 1.6rem;
}

.ecorg-cookie-panel > div {
	padding: 1rem;
}

.ecorg-cookie-panel > div:nth-child(2) {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.ecorg-cookie-panel > div:nth-child(2) > div:nth-child(2) {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.ecorg-cookie-panel > div:last-child {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.ecorg-cookie-panel img {
	width: 3rem;
}

.ecorg-cookie-panel .modal-content {
}

/*--------------
 * MODAL QUIZZ
 * -------------*/

.article-content .modal,
.modal {
	position: fixed;
	z-index: 6;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.6);
}
.article-content .modal-content,
.modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 3rem;
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
	width: 100%;
	max-width: 62rem;
	border-radius: 8px;
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}
.article-content .modal-content a,
.modal-content a,
.article-content .modal-content a:hover,
.modal-content a:hover {
	color: #fff;
}

.article-content .modal-overview-container,
.modal-overview-container {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-top: 4rem;
}

.article-content .modal-overview-container .check,
.modal-overview-container .check {
	border: 3px solid #fff;
	box-sizing: content-box;
}

.modal-overview-container .modal-overview-detail {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 4rem 0;
}

.modal-overview-container .modal-overview-detail > div:first-child {
	font-size: 1.6rem;
	padding: 1rem;
	text-align: center;
}

.modal-overview-container .modal-overview-detail div:last-child {
}

.article-content .modal-overview-container img,
.modal-overview-container img {
	width: 12rem;
	border-radius: 10%;
	margin: auto;
}
.article-content .modal-overview-container h1,
.modal-overview-container h1 {
	font-size: 3rem;
}
.article-content .modal-overview-container h2,
.modal-overview-container h2 {
	font-size: 2rem;
	font-weight: 400;
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 0;
}

.quizz-content-wrapper {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
}

.close {
	color: #fff;
	float: right;
	font-size: 4rem;
	position: absolute;
	top: -5rem;
	right: 0;
	transition: all 0.2s ease-in-out;
}

.close:hover,
.close:focus {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	text-decoration: none;
	cursor: pointer;
}

.quizz-number {
	font-family: 'Poppins';
	font-size: 1.9rem;
	color: rgb(9, 190, 139);
	color: var(--secondColor);
	margin-bottom: 1rem;
	font-weight: 600;
}

.quizz-question {
	font-family: 'Open Sans';
	font-size: 2rem;
	font-weight: 600;
	margin-bottom: 0.3rem;
	color: #01425e;
	color: var(--mainColor);
	letter-spacing: 0;
}

.modal-content .quizz-answer > div {
	font-size: 1.7rem;
	font-size: var(--standardTextSize);
}

.quizz-top-page-error {
	font-family: 'Open Sans';
	font-size: 1.4rem;
	color: #ff5666;
	color: var(--errorColor);
	margin-bottom: 1rem;
	margin-top: -1.4rem;
	display: none;
}

.number-of-answers {
	font-family: 'Open Sans';
	font-size: 1.4rem;
	color: #01425e;
	color: var(--mainColor);
	margin-bottom: 2.4rem;
}

.quizz-result-message {
	font-size: 1.4rem;
	margin-top: 0rem;
}

.quizz-valid-answer {
	color: rgb(9, 190, 139);
	color: var(--secondColor);
}
.quizz-wrong-answer {
	color: #ff5666;
	color: var(--errorColor);
}

.modal-content-results > div.quizz-question,
.modal-content-results > p {
	display: block;
	text-align: center;
	margin: 0 auto;
}

.modal-content-results > div.quizz-question {
	margin-bottom: 3.5rem;
	font-size: 3rem;
}

.modal-content-results > p {
	font-size: 1.6rem;
	line-height: 1.5;
	margin: 0 auto 1.5rem auto;
	max-width: 45rem;
}

.modal-content-results > p:first-of-type {
	margin-bottom: 0.5rem;
}

.modal-content-results > p a {
	color: #03638d;
	color: var(--mainColorClearer);
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
}

.modal-content-results > p a:hover {
	color: #3ea7d6;
	color: var(--mainColorClearest);
}

.modal-content-results > .quizz-number {
	font-size: 3.5rem;
	margin: 0rem auto 3rem auto;
}

.modal-content-results > .btn {
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
	margin: 4rem auto 0 auto;
}

.result-display {
	width: 100%;
}

.result-display .cert-text p,
h1 {
	margin: 1rem;
}

.result-display .cert-text h1 {
	font-size: 2.3rem;
	margin: 3.5rem;
}

.cert-results {
	border-radius: 8px;
	max-width: 95rem;
	width: 80%;
	-webkit-flex-direction: row;
	        flex-direction: row;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding: 0rem;
	background-color: #fff;
	margin: 0rem auto;
}

.curriculum-header .cert-display p,
.article-content .cert-display p,
.cert-display p {
	font-size: 2.2rem;
	margin: 1rem auto;
}

.cert-results div:nth-child(1),
.cert-results div:nth-child(2) {
	padding: 2rem;
	font-size: 1.5rem;
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
	width: 50%;
}

.cert-display {
	text-align: center;
	width: 100%;
}

.cert-display p:first-child,
.cert-display p:last-child {
	font-weight: 600;
	font-family: 'Poppins';
}

.cert-display p:nth-child(2) {
	font-size: 1.5rem;
}

/*---------------------
 * CURRICULUM DETAILS
 *--------------------*/

.curriculum-header-image {
	width: 100%;
}

.curriculum-header-image > img {
	max-width: 100%;
	width: 100%;
	height: auto;
}

.curriculum-header {
	margin: 0 auto;
	max-width: 920px;
	max-width: var(--maxWidthSections);
	padding: 4rem;
	padding: var(--regularHomePadding);
	margin-top: 4rem;
	margin-bottom: 2rem;
}

.curriculum-header h1 {
	font-size: 3.5rem;
	color: rgb(48, 50, 53);
	color: var(--textColor);
	margin-bottom: calc(4rem / 2);
	margin-bottom: calc(var(--regularHomePadding) / 2);
	font-weight: 600;
}

.curriculum-header p {
	font-size: 1.6rem;
	max-width: calc(920px * 0.75);
	max-width: calc(var(--maxWidthSections) * 0.75);
}

.curriculum-list-section {
	background: rgba(240, 240, 240, 0.504);
	background: var(--lightGreyBackground);
	padding: calc(4rem);
	padding: calc(var(--regularHomePadding));
}

.curriculum-list-section > div {
	max-width: 920px;
	max-width: var(--maxWidthSections);
	margin: 0 auto;
	padding: 0 4rem;
	padding: 0 var(--regularHomePadding);
	width: 100%;
	max-width: 80rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: stretch;
	        align-items: stretch;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

/*---------------------
 * SIMULATOR
 *--------------------*/

.transition-container-full-screen {
	display: -webkit-flex;
	display: flex;
	max-width: 1320px;
	margin: 4rem auto;
	padding: 0 4rem;
	padding: 0 var(--regularHomePadding);
	width: 100%;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: stretch;
	        align-items: stretch;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.transition-container-full-screen > div {
	width: calc(50% - 2rem);
	margin: 0 1rem;
	padding: 2rem;
}

.transition-container {
	display: -webkit-flex;
	display: flex;
	max-width: 1320px;
	width: 100%;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: stretch;
	        align-items: stretch;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.transition-container-full-screen .energy-label-header {
	min-height: 8rem;
}
.transition-container-full-screen .energy-label-header .diff-text-neutral {
	display: block;
	margin: 1rem auto;
	width: 7rem;
	font-size: 1.4rem;
}

.transition-container-full-screen .energy-label-header h3 {
	font-size: 2.5rem !important;
}

.simulator-list-section {
	margin-bottom: 15rem;
}

.transition-container > div {
	width: calc(100% - 2rem);
	padding: 2rem;
}

.transition-container > div:first-child {
	margin-bottom: 2rem;
}

/*---------------------
 * CURRICULUM THUMB
 *--------------------*/
.curr-thumb-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
	border: 1px solid #d4c9c9;
	border-radius: 10px;
	margin-bottom: calc(4rem / 2);
	margin-bottom: calc(var(--regularHomePadding) / 2);
	background: #fff;
}

.curr-thumb-container > div:first-child {
	width: 25rem;
	margin: 2rem 2rem;
}

.curr-thumb-container > div:first-child > img {
	width: 100%;
	height: auto;
	border-radius: 10%;
}

.curr-thumb-container > div:last-child {
	width: 100%;
	padding: 2rem 2rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.curr-thumb-container > div:last-child h2 {
	font-size: 3rem;
	color: #01425e;
	color: var(--mainColor);
	margin-bottom: calc(4rem / 4);
	margin-bottom: calc(var(--regularHomePadding) / 4);
}

.curr-thumb-container > div:last-child h2 a {
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
	font-size: 2.4rem;
}

.curr-thumb-container > div:last-child h2 a:hover {
	color: #03638d;
	color: var(--mainColorClearer);
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
}

.curr-thumb-container > div:last-child p {
	font-size: 1.6rem;
	line-height: 1.5;
}

/*------------
 * CHECKBOX
 *------------*/

.quizz-answer {
	display: block;
	margin-bottom: 15px;
}

.quizz-checkbox {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.quizz-separator {
	width: 100%;
	margin-bottom: 2.4rem;
}

.quizz-checkbox > div:first-child,
.quizz-checkbox > div:nth-child(3) {
	min-width: 2.4rem;
	width: 2.4rem;
	margin-right: 2rem;
}

.quizz-checkbox > div:nth-child(2),
.quizz-checkbox > div:nth-child(4) {
	width: calc(100% - 4.4rem);
}

.quizz-checkbox input {
	padding: 0;
	height: auto;
	height: initial;
	width: auto;
	width: initial;
	margin-bottom: 0;
	cursor: pointer;
}

.quizz-checkbox label {
	position: relative;
	cursor: pointer;
}

.quizz-checkbox input:before {
	content: '';
	-webkit-appearance: none;
	background-color: #fff;
	border: 1px solid rgb(48, 50, 53);
	border: 1px solid var(--textColor);
	padding: 1.1rem;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	border-radius: 5px;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
}

.quizz-checkbox:hover input:before {
	background-color: rgb(245, 245, 245);
}

.quizz-checkbox input:checked:after {
	content: '';
	display: block;
	position: absolute;
	top: calc(50% - 1rem);
	left: calc(50% - 0.4rem);
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 6px;
	height: 14px;
	border: solid rgb(9, 190, 139);
	border: solid var(--secondColor);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}

.quizz-checkbox-error input:checked:after {
	border: solid #ff5666;
	border: solid var(--errorColor);
	border-width: 0 3px 3px 0;
}

.rounded-checkbox:before {
	border-radius: 50% !important;
}

/* ----------------------------
 * ARTICLE
 * ---------------------------- */

.article-container {
	max-width: 820px;
	max-width: var(--maxWidthArticle);
	width: 100%;
	margin: 0 auto;
	padding: 4rem 4rem;
	padding: var(--regularHomePadding) var(--columnSidePadding);
}

.article-header {
	margin-bottom: 1rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.article-header-image {
	max-width: 100%;
}

.article-header-image img {
	max-width: 100%;
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
}

.article-header div.subtitle {
	font-size: 1.7rem;
	font-size: var(--standardTextSize);
	color: rgb(48, 50, 53);
	color: var(--textColor);
	opacity: 1;
	margin: 0;
	margin-top: 4rem;
	margin-top: var(--regularHomePadding);
}

.article-header h1 {
	font-size: 4rem;
	color: #01425e;
	color: var(--mainColor);
	letter-spacing: 1px;
	margin-bottom: 1rem;
}

.article-header .description-footer {
	font-size: 1.7rem;
	font-size: var(--standardTextSize);
	margin-top: 0;
	margin-bottom: 4rem;
	margin-bottom: var(--regularHomePadding);
	font-size: 1.8rem;
}

.article-header .description-footer .cur-description-icon {
	font-size: 2.4rem;
}

.article-header .description-footer .cur-icon-text {
	font-size: 1.7rem;
	font-size: var(--standardTextSize);
}

.article-content {
	font-size: 1.7rem;
	font-size: var(--standardTextSize);
	line-height: 1.45;
}

/* ----------------------------
 * UTILITIES
 * ---------------------------- */

.u-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.u-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
}

.u-text-center {
	text-align: center;
}

.u-display {
	display: block;
}

.u-hide {
	display: none;
}

.u-align-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
}

.article-content img.img-desktop {
	display: block;
}

.article-content img.img-mobile {
	display: none;
}

.pos-relative {
	position: relative;
}

.next-article {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
}

.next-article-icon {
	background: rgb(9, 190, 139);
	background: var(--secondColor);
	border-radius: 50%;
	height: 4rem;
	width: 4rem;
}

.arrow-next-styling {
	color: white !important;
	font-size: 2.4rem;
	vertical-align: middle;
	fill: white;
}

.before-after-container {
	width: 250px;
}

/* ----------------------------
 * ARTICLES
 * ---------------------------- */

.article-content p {
	margin-bottom: 2.5rem;
	line-height: 1.6;
	font-size: 1.7rem;
}

.article-content h2 {
	margin-bottom: 2.5rem;
	margin-top: 4rem;
	color: #01425e;
	color: var(--mainColor);
	font-size: 2.4rem;
}

.reading-progress-bar {
	position: fixed;
	height: 0.6rem;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: rgb(9, 190, 139);
	background-color: var(--secondColor);
}

.article-content img {
	width: 100%;
	max-width: 65rem;
	margin: 4.2rem auto 6.8rem auto;
	display: block;
	position: relative;
}

.image-source {
	position: relative;
	margin: 0 auto;
	width: 100%;
}

.image-source > img {
	max-width: 100%;
}

.image-source sup {
	top: 0.5rem;
}

.image-source > div {
	position: absolute;
	bottom: -2.2rem;
	right: 0;
	font-size: 1.4rem;
	line-height: 1.4rem;
}

.image-source > div:before {
	content: 'Source : ';
}

.article-separator {
	width: 100%;
	margin-top: 4rem;
	border-bottom: 1px solid #ccc;
	margin-bottom: 8rem;
}

.article-content a {
	color: #03638d;
	color: var(--mainColorClearer);
}

.article-content a:hover {
	color: #3ea7d6;
	color: var(--mainColorClearest);
	font-size: 700;
}

.article-content ul li {
	display: list-item;
	list-style: none;
	padding: 0 0 0 4rem;
	position: relative;
	margin: 0 0 1.6rem 0;
	line-height: 150%;
}

.article-content ul li::before {
	content: '';
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: rgb(9, 190, 139);
	background: var(--secondColor);
	position: absolute;
	left: 1rem;
	top: 1rem;
	vertical-align: middle;
}

.article-content ol {
	list-style: none;
	counter-reset: li;
}

.article-content ol li::before {
	content: counter(li);
	color: rgb(9, 190, 139);
	color: var(--secondColor);
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

.article-content ol li {
	counter-increment: li;
	margin: 0 0 1.6rem 2rem;
}

.related-articles {
	margin-top: 4rem;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-ms-flex-align: center;
}

.next-article-title {
	margin-right: 10px;
}

.next-article-title a {
	font-size: 2.4rem;
	color: #01425e;
	color: var(--mainColor);
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
}

.next-article-title a:hover {
	color: #03638d;
	color: var(--mainColorClearer);
}

/* -------------------------------------------------------------------
 * REFERENCEs
 * ------------------------------------------------------------------- */

.tooltip {
	position: relative;
	display: inline-block;
	background-image: linear-gradient(120deg, rgba(12, 238, 174, 0.301) 0%, rgba(12, 238, 174, 0.301) 100%);
	background-image: linear-gradient(120deg, var(--lightGreen) 0%, var(--lightGreen) 100%);
	background-repeat: no-repeat;
	background-size: 100% 0.2em;
	background-position: 0 90%;
}

.footnote-ref div.note-bubble {
    margin-left: 2px;
    background-color: rgba(165, 165, 165, 0.363);
    background-color: var(--lightGrey);
    padding: 0rem 0rem 0rem 0.5rem;
    color: rgb(30, 37, 37);
    font-size: 1.4rem;
    display: inline-block;
    /* border: 3px solid red; */
    border-radius: 50%;
    /* transition: var(--transitionQuick); */
    font-weight: 700;
    letter-spacing: 2px;
    cursor: pointer;
    position: relative;
    width: 2rem;
    height: 2rem;
    -webkit-transform: translateY(-0.4rem);
            transform: translateY(-0.4rem);
}

.image-source .footnote-ref div.note-bubble {
    margin-left: 5px;
    padding: 0.3rem 0rem 0rem 0.5rem;    
    -webkit-transform: translateY(-0rem);    
            transform: translateY(-0rem);
}

.footnote-ref div {
	margin-left: 2px;
	background-color: rgba(165, 165, 165, 0.363);
	background-color: var(--lightGrey);
	padding: 0.5rem 0.5rem 0.2rem 0.8rem;
	color: rgb(30, 37, 37);
	border-radius: 50%;
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
	font-weight: 700;
	letter-spacing: 2px;
	cursor: pointer;

	position: relative;
}

.footnote-ref div:hover {
	background-color: rgba(12, 238, 174, 0.301);
	background-color: var(--lightGreen);
}

.footnote-ref div:hover .container,
.footnote-ref div:hover .tooltip-transparent-panel {
	visibility: visible;
	opacity: 1;
}

.footnote-ref div .container a {
	color: #03638d;
	color: var(--mainColorClearer);
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
	display: inline;
	background-image: linear-gradient(rgba(12, 238, 174, 0.301) 0%, rgba(12, 238, 174, 0.301) 100%);
	background-image: linear-gradient(var(--lightGreen) 0%, var(--lightGreen) 100%);
	background-repeat: no-repeat;
	background-size: 100% 0rem;
	background-position: 0 93%;
	line-height: 1.5;
	border-radius: 0;
	transition: all 0.15s ease-in-out;
	transition: var(--transitionQuick);
}

.footnote-ref div .container a:hover {
	color: #01425e;
	color: var(--mainColor);
	background-size: 100% 0.3rem;
}

.footnote-ref div .container:after {
	top: calc(100% - 3px);
	left: 1rem;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #fff;
	border-width: 1.5rem;
	margin-left: -1.5rem;
	z-index: 2;
}

.footnote-ref div .container:before {
	content: '';
	position: absolute;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	width: 2rem;
	height: 2rem;
	bottom: -0.9rem;
	left: 0rem;
	z-index: -1;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.footnote-ref div .container {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	bottom: 4rem;
	left: 0rem;
	width: 2rem;
	display: none;
}

.footnote-ref div .tooltiptext {
	background-color: #fff;
	text-align: left;
	color: rgb(48, 50, 53) !important;
	color: var(--textColor) !important;	
	font-family: 'Open Sans';
	font-weight: 400;
	letter-spacing: 0;
	border-radius: 6px;
	padding: 2rem;
	line-height: 1.5;
	z-index: 1;
	bottom: 150%;
	box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
}

.footnote-ref div .container > div {
	min-width: 500px;
	background-color: #fff;
	text-align: left;
	color: rgb(48, 50, 53) !important;
	color: var(--textColor) !important;
	font-family: 'Open Sans';
	font-weight: 400;
	letter-spacing: 0;
	border-radius: 6px;
	padding: 2rem;
	line-height: 1.5;
	z-index: 1;
	bottom: 150%;
	border: 2px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
	-webkit-transform: translateX(-4rem);
	        transform: translateX(-4rem);
}

.tooltip-transparent-panel {
	opacity: 0;
	position: absolute;
	bottom: -5rem;
	height: 5rem;
	left: -8rem;
	width: 20rem;
	background: transparent;
	z-index: 5;
}

/* -------------------------------------------------------------------
 * ## post tags
 * ------------------------------------------------------------------- */

.entry__tags {
	margin-top: 4rem;
	margin-top: var(--regularHomePadding);
	font-family: 'Flama', sans-serif;
}

.entry__tags span {
	font-weight: 500;
	color: rgb(48, 50, 53);
	color: var(--textColor);
	padding-right: 56px;
	margin-right: 1.6rem;
	position: relative;
}

.entry__tags span::after {
	display: inline-block;
	content: '';
	height: 1px;
	width: 40px;
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 50%;
	right: 0;
}

.entry__tags .entry__tag-list {
	display: inline-block;
	padding-right: 0;
	margin-right: 0;
}

.entry__tags .entry__tag-list::after {
	display: none;
}

.entry__tags .entry__tag-list a {
	display: inline-block;
	font-weight: 400;
	font-size: 1.3rem;
	line-height: inherit;
	color: #1a1a1a;
	border: 1px solid rgb(9, 190, 139);
	border: 1px solid var(--secondColor);
	border-radius: 8px;
	margin-right: 6px;
	margin-bottom: 8px;
	padding: 0.5rem 2rem;
}

.entry__tags .entry__tag-list a:last-child {
	margin-right: 0;
}

.fade-appear {
	opacity: 0.01;
	transition: all 0.3s ease-in-out;
}

.fade-appear.fade-appear-active {
	opacity: 1;
	transition: all s ease-in-out;
}

.fade-enter {
	opacity: 0.01;
	transition: all 0.3s ease-in-out;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: all s ease-in-out;
}

/*--------------------------------
 * ANIMATIONS
 *--------------------------------*/

.animate-fadein {
	-webkit-animation-duration: 0.3s;
	        animation-duration: 0.3s;

	webkit-animation-name: fadein;
	-webkit-animation-name: fadein;
	        animation-name: fadein;
}

@keyframes fadein {
	from {
		opacity: 0.01;
		-webkit-transform: translateY(-s200px);
		        transform: translateY(-s200px);
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes fadein {
	from {
		opacity: 0.01;
	}

	to {
		opacity: 1;
	}
}

.animate-fadeout {
	-webkit-animation-duration: 0.3s;
	        animation-duration: 0.3s;
	webkit-animation-name: fadeout;
	-webkit-animation-name: fadeout;
	        animation-name: fadeout;
}

@-webkit-keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0.01;
	}
}

@keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0.01;
	}
}

/*-----------------------------
 * MEDIA QUERIES
 *-----------------------------*/

@media screen and (max-width: 1400px) {
	.home-quizz {
		padding: 0 calc(4rem * 2);
		padding: 0 calc(var(--columnSidePadding) * 2);
	}

	.prez-curriculums > div {
		width: 50%;
	}

	.hero-title {
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
	}

	.arrow-icon {
		margin-left: 0;
	}
}

@media screen and (max-width: 1024px) {
	.details-img {
		padding-right: 4rem;
	}

	/*
	.curr-thumb-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: calc(50% - calc(var(--regularHomePadding) / 2));
		min-width: auto;
	}

	.curr-thumb-container > div:first-child,
	.curr-thumb-container > div:last-child {
		width: 100%;
	}

	.curr-thumb-container > div:first-child > img {
		border-radius: 10px 10px 0 0;
	}

	.curr-thumb-container > div:last-child {
		height: 100%;
		padding: calc(var(--regularHomePadding) / 2);
		align-items: flex-start;
	}
    */

	.curriculum-list-section > div {
		padding: 0;
	}

	.prez-curriculums > div {
		max-width: 35rem;
	}
}

@media screen and (max-width: 900px) {
	.curriculum-list-section {
		padding: 4rem 1rem;
	}

	.article-container {
		padding: 2rem;
	}

	.modal-content {
		width: 100%;
		min-width: auto;
		margin-top: 2rem;
	}

	.curr-thumb-container {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.curr-thumb-container > div:first-child {
		margin: 0;
		width: 100%;
	}

	.curr-thumb-container > div:first-child > img {
		border-radius: 8px 8px 0 0;
	}

	.hero-text-container {
		-webkit-transform: translate(0, -65%);
		        transform: translate(0, -65%);
	}

	.nav-links {
		visibility: hidden;
	}

	.navbar .nav-header .burgermenu {
		display: block;
	}

    /*
	.footnote-ref div .container > div {
		font-size: 0.313rem !important;
        color : blue !important;
	}
    */  

	.simulator-list-section .coal-game,
	.article-content .coal-game {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.transition-container-full-screen {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.transition-container-full-screen > div {
		width: 100%;
		margin: 0;
		padding: 0;
		margin-top: 4rem;
	}

	.transition-container-full-screen .coal-game > div.transition-version {
	}

	.simulator-list-section .coal-game > div:first-child,
	.simulator-list-section .coal-game > div,
	.article-content .coal-game > div:first-child {
		width: calc(100% - 4rem);
		margin: 2rem 2rem 2rem 2rem;
	}

	.simulator-list-section .coal-game > div:nth-child(2),
	.article-content .coal-game > div:nth-child(2) {
		width: calc(100% - 4rem);
		margin: 0 2rem 2rem 2rem;
	}

	.simulator-list-section .coal-game > div:nth-child(3),
	.article-content .coal-game > div:nth-child(3) {
		width: calc(100% - 4rem);
		margin: 0 2rem 2rem 2rem;
	}

	.transition-container-full-screen .transition-results-container {
		-webkit-flex-direction: column;
		        flex-direction: column;
		padding-bottom: 2rem;
	}

	.transition-container-full-screen .transition-results-container > div {
		width: 100%;
	}

	.coal-header .c-intensity {
		font-size: 2.8rem;
	}


}

@media screen and (max-width: 768px) {
	.footer-section {
		padding: 2rem 0 !important;
	}

	.article-content .modal-content,
	.modal-content {
		padding: 2rem;
	}

	.curriculum-header {
		padding: 2rem;
	}

	.nav-header > a:first-child {
		margin-left: 0;
	}

	.nav-header > div:last-child {
		margin-right: 0;
	}

	.nav-header .nav-links .link-container {
		display: none;
	}

	.nav-header-open .nav-links .link-container {
		display: -webkit-flex;
		display: flex;
	}

	.curr-thumb-container:nth-child(odd) {
		margin-right: 0;
	}

	.description-footer > div.header-section {
		width: 100%;
		margin-top: 2rem;
	}

	.nav-header > .nav-links .link-container {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	#marker {
		display: none;
	}

	.nav-header > .nav-links .link-container div:not(:last-child) {
		margin: 0 0 3.5rem 0;
	}

	.nav-header > .nav-links .link-container div a {
		border-bottom: 2px solid transparent;
		padding-bottom: 0.5rem;
		transition: all 0.3s ease-in-out;
	}

	.nav-header > .nav-links .link-container div a:hover {
		border-bottom: 2px solid #fff;
	}

	.curr-thumb-container {
		width: 100%;
		max-width: 38rem;
	}

	.details-text {
		padding: 0;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}

	.details-text p {
		margin-bottom: 4rem;
	}

	.close {
		color: #01425e;
		color: var(--mainColor);
		top: 0.5rem;
		right: 2rem;
	}

	.benefit {
		padding-right: 1rem;
		padding-left: 1rem;
		border: 1px solid #a9a9a9;
		border: 1px solid var(--legendcolor);
		background: #fff;
		border-radius: 8px;
	}

	.benefit .icon {
		padding: 0 1rem;
	}

	.home-quizz {
		padding: 0 4rem;
		padding: 0 var(--regularHomePadding);
	}

	.prez {
		padding: 0 1rem;
		width: 100%;
	}

	.prez p {
		padding: 0 1rem;
	}

	.prez h1 {
		height: auto;
	}

	.hero-text-container {
		min-width: auto;
	}

	.article-content img.img-desktop {
		display: none;
	}

	.article-content img.img-mobile {
		display: block;
	}

	.prez h1 {
		margin-bottom: 4rem;
		margin-bottom: var(--regularHomePadding);
	}

	.prez-curriculums > div {
		max-height: auto;
		height: auto;
	}

	.cur-container {
		margin-bottom: 4rem;
		margin-bottom: var(--regularHomePadding);
	}

	.system {
		margin: 0 4rem;
		margin: 0 var(--regularHomePadding);
	}

	.home-quizz > div:first-child {
		display: none;
	}

	.home-quizz-text {
		width: 100%;
		max-width: 38rem;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		text-align: center;
		margin: 0 auto;
	}

	.details-img {
		display: none;
	}

	.details-text {
		text-align: center;
	}

	.details-text h1 {
		max-width: 500px;
	}

	.details-section-container {
		height: auto;
		padding: calc(4rem * 3) 0;
		padding: calc(var(--regularHomePadding) * 3) 0;
	}

	.benefit-container {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
		padding: 1rem;
	}

	.benefit {
		width: 90%;
	}

	.prez-curriculums {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
	}

	.prez-curriculums > div:not(:last-child) {
		margin-right: 0;
	}

	.prez-curriculums > div {
		width: 100%;
	}

	.home-quizz > div:first-child {
		margin-right: calc(9rem / 2);
		margin-right: calc(var(--centralMarginHome) / 2);
	}

	.footer-section > div {
		width: calc(100% - 4rem) !important;
		margin-left: 0 impor !important;
	}

	.footer-section > div:nth-child(3) {
		padding: 2rem !important;
	}
}

@media screen and (max-width: 600px) {
	html {
		font-size: 57%;
	}

	.home-quizz > div:last-child {
		width: 100%;
		text-align: center;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}
}

@media screen and (max-width: 480px) {
	.hero-text-container {
		width: 100%;
		padding: calc(4rem / 2);
		padding: calc(var(--regularHomePadding) / 2);
	}

	.hero-text-container h1 {
		text-align: center;
		font-size: 2rem;
		width: 100%;
	}

	.hero-text-container h2 {
		font-size: 2.8rem;
		text-align: center;
		line-height: 1.7;
	}

	.hero-btn-container {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-content: center;
		        align-content: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.hero-btn-container .btn {
		margin: 2rem auto;
	}

	.hero-btn-container .green-btn {
		margin: auto;
	}

	.hero-btn-container > div {
		margin-top: 1rem;
	}
}

/*-----------------------------
 * FOG EFFECT
 *-----------------------------*/

.absolute-bg {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: 100%;
	width: 100%;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.fog {
	position: relative;
	height: 100vh;
	width: 100%;
	background-color: #000;
}
.fog__container {
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
}
.fog__img {
	position: absolute;
	height: 100vh;
	width: 300vw;
}
.fog__img--first {
	/* background: url("https://www.kindpng.com/picc/b/204/2043421.png") repeat-x; */
	background-size: contain;
	background-position: center;
	-webkit-animation: marquee 40s linear infinite;
	animation: marquee 40s linear infinite;
}
.fog__img--second {
	background-size: contain;
	background-position: center;
	-webkit-animation: marquee 40s linear infinite;
	animation: marquee 40s linear infinite;
}

@-webkit-keyframes marquee {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		-webkit-transform: translate3d(-200vw, 0, 0);
		transform: translate3d(-200vw, 0, 0);
	}
}

@keyframes marquee {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		-webkit-transform: translate3d(-200vw, 0, 0);
		transform: translate3d(-200vw, 0, 0);
	}
}

/*-----------------------------
 * TRANSITION GAME
 *-----------------------------*/

.transition-results-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	margin-bottom: 2rem;
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
	border-radius: 10px;
	padding: 1rem 2rem;
	width: 100%;
}

.transition-results-container > div:not(:last-child) {
	padding-right: 3rem;
}

.transition-results-container > h3 {
	width: 100%;
	text-align: center;
	padding: 1rem 0 2rem 0;
}

.transition-results-container > div {
	width: 50%;
}

.transition-results {
	max-width: 460px;
	width: 100%;
	margin: 0 auto;
	padding: 2rem 4rem;
}

.transition-results .tr-results {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	position: relative;
	margin: 0;
}

.tr-results > .result-blocker {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% + 2rem);
	height: 100%;
	right: 100%;
	z-index: 5;
	background-color: transparent;
}

.tr-results-label {
	padding: 0 1rem;
}

.transition-results > div {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.transition-results > div > div:first-child {
	padding: 0 1rem;
}

.transition-results > div > div:last-child {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.transition-results > div.game-icon-list {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}

.transition-results > div.game-icon-list > div:not(:last-child) {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	width: 100%;
	margin: 0;
	padding: 0;
	margin-bottom: 1rem;
}

.transition-results > div.game-icon-list > div div:first-child {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.transition-results > div.game-icon-list > div div:first-child img {
	max-height: 3rem;
	height: 3rem;
	width: 3rem;
	margin: 0;
	width: auto;
}

.transition-results > div.game-icon-list > div div:last-child {
	width: 100%;
	font-size: 1.4rem;
	color: rgb(48, 50, 53);
	color: var(--textColor);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	padding-left: 1rem;
}

.slider-section-borders {
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
	border-radius: 10px;
}

.transition-slider-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: center;
	        justify-content: center;
	max-width: 920px;
	max-width: var(--maxWidthSections);
	margin: 0 auto;
	position: relative;
	width: 100%;
}

.slider-container {
	padding: 2rem 2rem 1rem 2rem;
	min-width: 240px;
	border-radius: 10px;
	margin-right: calc(4rem / 2);
	margin-right: calc(var(--regularHomePadding) / 2);
}

.slider-container > div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.game-container {
	width: 100%;
}

.coal-game {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	max-width: 920px;
	max-width: var(--maxWidthSections);
	margin: 0 auto;
	position: relative;
}

.energy-label div .smoke-icon {
	position: absolute;
	bottom: 4.7rem;
	left: 0.2rem;
	height: 3.5rem;
	width: auto;
	margin: 0;
	z-index: -1;
}

.stockage-type-panel {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.stockage-type-panel > div {
	border: 1px solid rgba(48, 50, 53, 0.1);
	border-radius: 10px;
	padding: 0.5rem;
	font-size: 1.2rem;
	margin: 0.5rem;
	cursor: pointer;
}

.stockage-selected {
	background-color: rgba(48, 50, 53, 0.1);
	border-color: transparent;
}

.stockage-type-panel > div:hover {
	background-color: rgba(48, 50, 53, 0.1);
}

.game-icons {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	border-radius: 10px;
	overflow: auto;
}

.game-icons > section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	border-radius: 10px;
	width: 100%;
}

.game-icons > section h3 {
	display: block;
}

.coalGame-row {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 1rem;
}

.div-energy-icon {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0.3rem;
	max-width: 4rem;
	width: 3.5rem;
}

.div-energy-icon img {
	max-width: 3.5rem;
	margin: 0;
}

.div-energy-icon > div {
	font-family: 'Flama';
	font-family: 'Flama';
	font-size: 1.2rem;
}

.coal-game > div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.coal-game > div {
	border: 1px solid rgba(165, 165, 165, 0.363);
	border: 1px solid var(--lightGrey);
}

.coal-game > div:first-child {
	width: 35%;
	padding: 2rem 2rem 1rem 2rem;
	min-width: 240px;
	border-radius: 10px;
	margin-right: calc(4rem / 2);
	margin-right: calc(var(--regularHomePadding) / 2);
}

.coal-game > div:last-child {
	width: 100%;
	padding: calc(4rem / 2);
	padding: calc(var(--regularHomePadding) / 2);
	padding-bottom: 5rem;
}

.coal-game > div.transition-version {
	width: 33%;
	padding: 0;
	min-width: 0;
	border-radius: 10px;
	margin-right: 0;
	margin-left: 0;
	padding: 1rem;
	width: 33%;
	padding-bottom: 1rem;
	border: none;
}

.coal-header {
	width: 100%;
	max-width: calc(4rem * 5);
	max-width: calc(var(--regularHomePadding) * 5);
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin: 0 auto;
}

.energy-label .coal-icon {
	margin: 0;
	display: inline;
	width: 5rem;
	margin-right: 1rem;
}

.coal-game-source {
	font-size: 1.2rem;
	right: 0;
	z-index: 10;
	text-align: right;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
}

.align-label {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.game h3 {
	margin: 0 auto 2.4rem auto;
	color: rgb(48, 50, 53);
	color: var(--textColor);
}

.closed-icon {
	border-radius: 8px;
	box-shadow: 14px 20px 29px -25px rgba(0, 0, 0, 0.75);
	background: rgb(0, 167, 123);
	background: var(--secondColorDark);
	font-family: 'Flama';
	letter-spacing: 1px;
	position: absolute;
	color: #fff;
	padding: 0.3rem 0.5rem;
	font-size: 1rem;
}

.coal-header div:last-child {
	margin-bottom: 2rem;
}

.coal-header h3 {
	font-size: 1.6rem;
}

.emissions-objective-slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	right: 100%;
	z-index: 3;
	background-color: rgb(9, 190, 139);
	background-color: var(--secondColor);
	background-color: transparent;
}

.emissions-objective-slider > span {
	background-color: rgba(9, 188, 138, 0.2);
	background-color: rgb(9, 190, 139);
	background-color: var(--secondColor);
	width: 2px;
	border-radius: 2px;
	height: 2.5rem;
	display: block;
	position: relative;
	margin-left: calc(8%);
}

.emissions-objective-slider > span > span {
	position: absolute;
	bottom: -2.5rem;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	font-size: 1.4rem;
	border: 1px solid #fff;
	background-color: #fff;
}

.c-intensity {
	color: #01425e;
	color: var(--mainColor);
	font-size: 2rem;
	position: relative;
	margin: 1rem auto;
	display: inline-block;
}

.unit-main {
	font-size: 1.3rem;
	color: #a9a9a9;
	color: var(--legendcolor);
	opacity: 0.9;
	display: block;
	bottom: 0;
	right: 0;
}

.energy-icons {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-justify-content: center;
	        justify-content: center;
}

.energy-icons > div {
	margin: 0rem 1rem;
}

.unit-sub {
	font-size: 1rem;
	color: #a9a9a9;
	color: var(--legendcolor);
	opacity: 0.7;
}

.game {
	margin: 0 auto;
	max-width: 500px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: wrap;
	        flex-direction: wrap;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	/*  */
}

.game > section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: wrap;
	        flex-direction: wrap;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
}

.game > section > label {
	width: 100%;
	font-size: 1.6rem;
	text-align: left;
}

.MuiSlider-root {
	padding: 1rem !important;
}

.game > section > div {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: wrap;
	        flex-direction: wrap;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
	position: relative;
	margin-bottom: 1rem;
}

.game > section > div .todays-trend,
.game > section > div .todays-trend-transparent {
	position: absolute;
	height: 18px;
	width: 2px;
	border-radius: 5px;
	top: 50%;
	left: 30px;
	background-color: #bfe5d4;
	-webkit-transform: translateY(calc(-50% - 3px));
	        transform: translateY(calc(-50% - 3px));
	z-index: 0;
}

.game > section > div .todays-trend-transparent {
	background-color: rgba(12, 238, 174, 0);
	z-index: 6;
}

.game > section > div .co2-objectif {
	background-color: rgb(48, 50, 53);
	background-color: var(--textColor);
	height: 20px;
	left: 10%;
}

.game > section > div .game-twenty-twenty {
	display: none;
}

.game > section > div .co2-objectif::after {
	position: absolute;
	top: -20px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	content: '2 °C';
	font-size: 1.4rem;
	color: rgb(48, 50, 53);
	color: var(--textColor);
	font-family: 'Open Sans';
	font-weight: 700;
	padding: 0.5rem 1rem 0.2rem 1rem;
	border-radius: 5px;
	transition: all 0.8s ease-in-out;
}

.game > section > div .co2-objectif-passed {
}

.game > section > div .co2-objectif-passed::after {
}

.game-icon-list {
	display: -webkit-flex;
	display: flex;
	width: 100%;
}

.todays-trend-transparent > span {
	display: none;
	z-index: 6 !important;
}

.todays-trend-transparent:hover > span {
	display: block;
	border: 2px solid #a9a9a9;
	border: 2px solid var(--legendcolor);
	color: rgb(48, 50, 53);
	color: var(--textColor);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
	content: 'Tendance Actuelle';
	z-index: 6 !important;
	width: 120px;
	height: 50px;
	padding: 1rem;
	background-color: #fff;
	border-radius: 5px;
	font-size: 1.3rem;
	position: absolute;
	top: -60px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.energy-label {
	font-family: 'Open Sans';
	font-size: 1.6rem;
}

.energy-label .energy-label-legend {
	display: block;
	font-size: 1.3rem;
	padding: 0.3rem 0;
	color: #a9a9a9;
	color: var(--legendcolor);
}

.diff-text-below,
.diff-text-above,
.diff-text-neutral {
	font-size: 1.3rem;
	padding: 0.3rem 0.5rem;
	border-radius: 5px;
	color: rgba(48, 50, 53, 0.6);
}

.diff-text-below {
	color: #ff5666;
	color: var(--errorColor);
	background-color: rgba(255, 86, 102, 0.15);
	color: rgb(48, 50, 53);
	color: var(--textColor);
	background-color: rgba(48, 50, 53, 0.1);
	letter-spacing: 1px;
}

.diff-text-above {
	background-color: rgba(9, 188, 138, 0.2);
	color: rgb(0, 167, 123);
	color: var(--secondColorDark);
	color: rgb(48, 50, 53);
	color: var(--textColor);
}

.diff-text-neutral {
	color: rgb(48, 50, 53);
	color: var(--textColor);
	background-color: rgba(12, 238, 174, 0.301);
	color: rgb(48, 50, 53);
	color: var(--textColor);
	background-color: rgba(48, 50, 53, 0.1);
	letter-spacing: 1px;
}

.co2-reduction {
	color: #fff;
	background-color: #01425e;
	background-color: var(--mainColor);
	letter-spacing: 0px;
	transition: all 0.3s ease-in-out;
}

.co2-reduction-success {
	color: #fff;
	background-color: rgb(9, 190, 139);
	background-color: var(--secondColor);
}

.co2-progress-bar {
	width: 100%;
	background-color: rgb(9, 190, 139);
	background-color: var(--secondColor);
}

.section-w-margin-top {
	margin-top: 4rem;
	margin-top: var(--regularHomePadding);
}

.section-w-margin-top-half {
	margin-top: calc(4rem / 2);
	margin-top: calc(var(--regularHomePadding) / 2);
}

.game div .co2-objectives {
	position: absolute;
	padding: 1px;
	width: 4px;
	border-radius: 8px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	left: calc(10% + 6px);
	height: 100%;
	border-radius: 5px;
	z-index: -1;
	background-color: gold;
}

.game div .co2-objectives::after {
	position: absolute;
	content: 'Objectifs 2 °C';
	top: 100%;
	text-align: center;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	padding: 0.5rem;
	font-size: 1.4rem;
	font-family: 'Poppins';
}

.slider-w-margin-bottom {
	margin-bottom: calc(4rem * 1.5);
	margin-bottom: calc(var(--regularHomePadding) * 1.5);
}

.slider-w-margin-bottom_small {
	margin-bottom: calc(4rem);
	margin-bottom: calc(var(--regularHomePadding));
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 100%;
}

.dot {
	height: 10px;
	width: 10px;
	background-color: rgba(165, 165, 165, 0.363);
	background-color: var(--lightGrey);
	border-radius: 50%;
	display: block;
	margin: 0 auto calc(4rem / 2) auto;
	margin: 0 auto calc(var(--regularHomePadding) / 2) auto;
}

.form__body > div.conditions {
	opacity: 0.7;
	margin: 1rem auto 1rem auto;
	text-align: left;
}

.form__body > div.signup-panel,
.form__body > div.login-panel {
	font-size: 1.4rem;
	text-align: center;
	color: #919191;
	font-family: 'Poppins';
	margin-top: 1.3rem;
}

.form__body > div.signup-panel {
	margin-top: 1.3rem;
}

.form__body > div.login-panel {
	margin-top: 2rem;
}

.form__body .forgot-pasword {
	width: 250px;
	margin: 7px auto 7px auto;
	text-align: center;
	font-size: 1.3rem;
}

.form__body a {
	color: #3ea7d6;
	color: var(--mainColorClearest);
	transition: all 0.3s;
	opacity: 1;
}

.form__body a:hover {
	color: #03638d;
	color: var(--mainColorClearer);
}

.form__body .submit-loader {
	position: relative;
	width: 250px;
	margin: 7px auto 7px auto;
}

.form__body .submit-loader input {
	margin: 7px 0 7px 0;
}

.form__body .submit-loader > div {
	position: absolute;
	top: 15px;
	right: 10px;
}

.form__body .accept-conditions {
	width: 250px;
	margin: 7px auto 7px auto;
	text-align: left;
	font-size: 12px;
}

.form__body .accept-conditions ul li {
	margin-left: 20px;
}

.no-account {
	font-size: 1.6rem;
	transition: all 0.2s;
}

.no-account:hover {
	color: #3ea7d6;
	color: var(--mainColorClearest);
}

.why-account {
	padding: 1rem 3rem 0rem 3rem;
	text-align: left;
}

.why-account p {
	font-size: 1.6rem;
	margin-bottom: 2rem;
}
.why-account a,
.accept-conditions a {
	display: inline;
}

.quizz-article-inline {
	padding: 5rem 0;
}

.submit-google {
	border: 1px solid #cccdd1;
	position: relative;
	width: 250px;
	margin: 7px auto 7px auto;
	height: 40px;
	border-radius: 5px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.submit-google div {
	cursor: pointer;
	width: 100%;
	font-size: 14px;
	font-weight: 600 !important;
	text-align: left;
	padding-left: 0.5rem;
}

.submit-google img {
	width: 24px;
	margin-left: 8px;
	margin-right: 8px;
}

/* ----------------------------------------------- */
/* SCROLL DOWN */
/* ----------------------------------------------- */

.scroll-down {
	opacity: 1;
	transition: all 0.5s ease-in 3s;
	/* border-bottom: 2px solid #fff !important; */
}

.scroll-down {
	position: absolute;
	bottom: 50px;
	left: 50%;
	margin-left: -16px;
	display: block;
	width: 45px;
	height: 45px;
	/* border: 2px solid #fff;*/
	background-size: 14px auto;
	border-radius: 50%;
	z-index: 2;
	/*
	-webkit-animation: bounce 2s infinite 3s;
	animation: bounce 2s infinite 3s;
    
	-webkit-transition: all 0.2s ease-in;
    */
	transition: all 0.2s ease-in;
	-webkit-transform: scale(1);
	        transform: scale(1);
}

.scroll-down:before {
	position: absolute;
	top: calc(50% - 12px);
	left: calc(50% - 9px);
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	display: block;
	width: 15px;
	height: 15px;
	content: '';
	border: 2px solid #fff;
	border-width: 0px 0 2px 2px;
}

@-webkit-keyframes bounce {
	0%,
	100%,
	20%,
	50%,
	80% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	60% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
}

@keyframes bounce {
	0%,
	100%,
	20%,
	50%,
	80% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	60% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
}

/*********************
 PROGRESS BAR
*********************/

.progress {
	padding: 0;
	width: 90%;
	height: 2rem;
	overflow: hidden;
	background: #e5e5e5;
	border-radius: 6px;
}

.bar {
	position: relative;
	float: left;
	min-width: 1%;
	height: 100%;
	background: rgb(9, 190, 139);
	background: var(--secondColor);
}

.percent {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	margin: 0;
	font-size: 1.4rem;
	line-height: 1.4rem;
	color: white;
}

/*********************
 FOOTER
*********************/

.footer-section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
	background-color: #01425e;
	background-color: var(--mainColor);
	color: #fff;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	padding: 2rem 4rem 1rem 4rem;
	font-size: 1.4rem;
	position: relative;
}

.footer-section a {
	color: #fff;
}

.footer-section a:hover {
	color: #d4c9c9;
}

.footer-section > div {
	width: 30%;
	padding: 2rem;
}

.footer-section > div:last-child {
	width: 100%;
	max-width: 100%;
	font-size: 1.1rem;
	text-align: center;
	padding: 2rem;
}

.footer-section > div:nth-child(3) {
	padding: 1rem 4rem;
}

.footer-section strong {
	font-family: 'Poppins';
}

.footer-section ul li {
	list-style: none;
	margin-bottom: 16px;
}

.footer-logo {
	height: 3.2rem;
}

/*********************
    CONTACT US
*********************/

.contact-us-form {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	max-width: 40rem;
}

.cu-form-container h1 {
	font-size: 2.4rem;
}

.cu-form-container p {
	font-size: 1.6rem;
	margin: 2rem auto;
}

.contact-us-form > div {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-bottom: 1rem;
}

.contact-us-form label {
	font-size: 1.6rem;
	font-weight: 600;
	font-family: 'Poppins';
	margin-top: 2rem;
}

.contact-us-form input[type='textarea'] {
	height: 20rem;
	border-radius: 8px;
	border: 1px solid #d4c9c9;
}

.contact-us-form input[type='submit'] {
	background: rgb(9, 190, 139);
	background: var(--secondColor);
	color: #fff;
	font-family: 'Open Sans';
	font-size: 1.8rem;
	padding: 1rem;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.contact-us-form input[type='text'] {
	font-size: 1.6rem;
	font-family: 'Open Sans';
	height: 3.5rem;
	border-radius: 8px;
	border: 1px solid #d4c9c9;
	padding: 5px;
}

.cu-form-container {
	padding: 4rem;
	border: 1px solid #d4c9c9;
	margin: 0 auto;
	max-width: 44rem;
	border-radius: 8px;
	background: #fff;
}

.cu-overall-container {
	background: rgba(240, 240, 240, 0.504);
	background: var(--lightGreyBackground);
	padding: 6rem 2rem;
	min-height: 50rem;
}

div.arrow-icon {
	display: inline-block;
	vertical-align: middle;
}

a {
	display: inline-block;
	border-radius: 50%;
}

a:hover .nav-arrov-right {
	background-color: #00a77b;
}

.nav-arrov-right,
.check {
	display: inline-block;
	width: 4rem;
	height: 4rem;
	background-color: #09bc8a;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.nav-arrov-right:hover {
	background-color: #00a77b;
}

.nav-arrov-right:hover:after {
	margin-left: 1.2rem;
}

.check:after {
	content: '';
	display: inline-block;
	margin-top: 1.1rem;
	margin-left: 0.9rem;
	width: 1.9rem;
	height: 1.2rem;
	border-top: 0.2rem solid #fff;
	border-right: 0.2rem solid #fff;
	-webkit-transform: rotate(130deg);
	transform: rotate(130deg);
	transition: all 0.3s ease-in-out;
}

.nav-arrov-right:after {
	content: '';
	display: inline-block;
	margin-top: 1.1rem;
	margin-left: 0.9rem;
	width: 1.3rem;
	height: 1.2rem;
	border-top: 0.2rem solid #fff;
	border-right: 0.2rem solid #fff;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	transition: all 0.3s ease-in-out;
}

.left {
	display: inline-block;
	width: 4em;
	height: 4em;
	border-radius: 50%;
	margin-right: 1.5em;
}

.left:after {
	content: '';
	display: inline-block;
	margin-top: 1.05em;
	margin-left: 0.6em;
	width: 1.4em;
	height: 1.4em;
	border-top: 0.3rem solid #333;
	border-right: 0.3rem solid #333;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.top {
	display: inline-block;
	width: 4em;
	height: 4em;
	border: 0.5em solid #333;
	border-radius: 50%;
	margin-right: 0.75em;
}

.top:after {
	content: '';
	display: inline-block;
	margin-top: 1.4em;
	width: 1.4em;
	height: 1.4em;
	border-top: 0.5em solid #333;
	border-right: 0.5em solid #333;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.bottom {
	display: inline-block;
	width: 4em;
	height: 4em;
	border: 0.5em solid #333;
	border-radius: 50%;
	margin-left: 0.75em;
}

.bottom:after {
	content: '';
	display: inline-block;
	margin-top: 0.6em;
	width: 1.4em;
	height: 1.4em;
	border-top: 0.5em solid #333;
	border-right: 0.5em solid #333;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

.water-cycle-container .sun {
    
  display: block;
  width : auto; 
  height: 60px;
  margin : 0;
  position: absolute;
  right: 10%;
  top: 0;
}

.water-cycle-container {
  width: 420px;
  position: relative;
  margin : auto;
}

#Arrows {
  opacity: 0.2;
}
#Arrows #a_evaporation path {
  -webkit-animation: a_evap 0.8s infinite alternate ease-in-out;
          animation: a_evap 0.8s infinite alternate ease-in-out;
}
#Arrows #a_transpiration {
  display: none;
  -webkit-animation: a_evap 0.8s infinite     alternate ease-in-out;
          animation: a_evap 0.8s infinite     alternate ease-in-out;
}
#Arrows #a_condensation {
  -webkit-animation: a_evap 0.8s infinite     alternate ease-in-out;
          animation: a_evap 0.8s infinite     alternate ease-in-out;
}
#Arrows #a_precipitation {
  display: none;
  -webkit-animation: a_evap 0.8s infinite       alternate ease-in-out;
          animation: a_evap 0.8s infinite       alternate ease-in-out;
}
#Arrows #a_runoff {
  display: none;
  -webkit-animation: a_evap 0.8s infinite       alternate ease-in-out;
          animation: a_evap 0.8s infinite       alternate ease-in-out;
}
#Arrows #a_perculation {
  display: none;
  -webkit-animation: a_evap 0.8s infinite      alternate ease-in-out;
          animation: a_evap 0.8s infinite      alternate ease-in-out;
}

.prefix__wave {
  -webkit-animation: wave 4s infinite alternate ease-in-out;
          animation: wave 4s infinite alternate ease-in-out;
}

#rain path {
  -webkit-animation: rain 2s infinite alternate linear;
          animation: rain 2s infinite alternate linear;
}
#rain path:nth-child(1) {
  -webkit-animation-delay: 0.0s;
          animation-delay: 0.0s;
}
#rain path:nth-child(2) {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
}
#rain path:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
#rain path:nth-child(4) {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s;
}
#rain path:nth-child(5) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
#rain path:nth-child(6) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
#rain path:nth-child(7) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
#rain path:nth-child(8) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

#clouds path {
  -webkit-animation: clouds 8.5s infinite linear;
          animation: clouds 8.5s infinite linear;
}
#clouds path:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
#clouds path:nth-child(2) {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
}

.atom {
  height: 25px;
  top: 4.8%;
  right: 14.2%;
}

@-webkit-keyframes rain {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.2;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes rain {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.2;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes wave {
  0% {
    opacity: 0.5;
    -webkit-transform: translate(20px, 10px);
            transform: translate(20px, 10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    -webkit-transform: translate(-40px, -20px);
            transform: translate(-40px, -20px);
  }
}
@keyframes wave {
  0% {
    opacity: 0.5;
    -webkit-transform: translate(20px, 10px);
            transform: translate(20px, 10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    -webkit-transform: translate(-40px, -20px);
            transform: translate(-40px, -20px);
  }
}
@-webkit-keyframes a_evap {
  0% {
    opacity: 0.2;
  }
  65% {
    opacity: 1;
  }
}
@keyframes a_evap {
  0% {
    opacity: 0.2;
  }
  65% {
    opacity: 1;
  }
}
@-webkit-keyframes clouds {
  0% {
    opacity: 1;
    -webkit-transform: translate(-20px, 0px);
            transform: translate(-20px, 0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translate(0px, 20px);
            transform: translate(0px, 20px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translate(20px, 10px);
            transform: translate(20px, 10px);
  }
  75% {
    opacity: 1;
    -webkit-transform: translate(0px, -20px);
            transform: translate(0px, -20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-20px, 0px);
            transform: translate(-20px, 0px);
  }
}
@keyframes clouds {
  0% {
    opacity: 1;
    -webkit-transform: translate(-20px, 0px);
            transform: translate(-20px, 0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translate(0px, 20px);
            transform: translate(0px, 20px);
  }
  50% {
    opacity: 1;
    -webkit-transform: translate(20px, 10px);
            transform: translate(20px, 10px);
  }
  75% {
    opacity: 1;
    -webkit-transform: translate(0px, -20px);
            transform: translate(0px, -20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-20px, 0px);
            transform: translate(-20px, 0px);
  }
}
.blinking {
  -webkit-animation: blinkingAnim 1.5s infinite;
          animation: blinkingAnim 1.5s infinite;
}

@-webkit-keyframes blinkingAnim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes blinkingAnim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.factoryContainer {
   height : 340px;
   position: relative;
   display: -webkit-flex;
   display: flex;
   -webkit-justify-content: center;
           justify-content: center;
   -webkit-align-items: flex-end;
           align-items: flex-end;
   padding : 2rem 0 4rem 0;
   max-width: 520px;
   margin : 0 auto;
   border : 1px solid #EAEAEA;
   border-radius: 10px;
}

.factory-selector{
    
    display : -webkit-flex;
    
    display : flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: absolute;
    top : 2rem;
    left : 50%;
    width: 380px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); 
}

.factory-selector-btn {

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: relative;
    padding : 0 1rem;
    width : 25%;
}

.factory-selector-btn > label {
    font-size : 1.4rem;
    padding : 0.5rem;
    text-align: center;
}

.factory-selector-btn > input {
    position: relative;
    margin : 0.5rem;
}

.factory-selector-btn img {
    max-width : 3.5rem;
    margin : 0;
}

.factoryContainer .stage {
    overflow: hidden;
    width: 500px;
    height: 230px;
    margin : 0 auto;
}

.factoryContainer .stage .factory {
    position: absolute;
    width: 75px;
    height: 65px;
    background: #EAEAEA;
    -webkit-transform: translate(45px, 163px);
            transform: translate(45px, 163px);
}

.factoryContainer .stage .factory::before {
    background: #d9d9d9;
    border-radius: 1px;
    width: 85px;
    height: 10px;
    top: -10px;
    left: -5px;
    box-shadow: -4px 12px 21px -21px rgba(0,0,0,0.75);
}

.factoryContainer .stage .factory::after {
    width: 20px;
    height: 20px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    background-color: #c96160;
}

.factoryContainer .stage .magic {

    width: 40px;
    height: 45px;
    position: absolute;
    border-radius: 0 0 3px 3px;
    -webkit-transform: translate(0, -60px);
            transform: translate(0, -60px);
    background: rgb(142,225,235);
    background: linear-gradient(90deg, rgba(142,225,235,0.3841911764705882) 31%, rgba(210,219,221,0.38139005602240894) 100%);
}
.factoryContainer .stage .magic::before {
    
    background: #cccccc;
    bottom: -5px;
    left: 20%;
    width: 60%;
    height: 5px;
}

.factoryContainer .magic::after {

    background: #56BA8E;
    border-radius: 0 0 3px 3px;
    bottom: 3px;
    left: 3px;
    width: 34px;
    height: 70%; 
    -webkit-animation-name: upDown; 
            animation-name: upDown;
    -webkit-animation-duration: 6000ms;
            animation-duration: 6000ms;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;  
}

.factoryContainer .stage .factory .arm::after {
    background: #F3D1C2;
    -webkit-animation-duration : 6000ms;
            animation-duration : 6000ms;
}

.factoryContainer .stage .factory .wood::after {
    background: #8C5A40;
    -webkit-animation-duration : 3000ms;
            animation-duration : 3000ms;
}

.factoryContainer .stage .factory .coal::after {
    background: #333538;
    -webkit-animation-duration : 1333ms;
            animation-duration : 1333ms;
}

.factoryContainer .stage .factory .oil::after {
    background: #405D7A;
    -webkit-animation-duration : 1000ms;
            animation-duration : 1000ms;
}

.factoryContainer .band {
    position: absolute;
    width: 350px;
    height: 15px;
    background: #364451;
    border-radius: 8px;
    -webkit-transform: translate(50px, 210px);
            transform: translate(50px, 210px);
}
.factoryContainer .band::before {
    background-image: radial-gradient(#4b5d6f 40%, transparent 41%);
    background-size: 15px 15px;
    background-position: 0px 0px;
    width: 90%;
    height: 15px;
    top: 0px;
    right: 5px;
    z-index: 2;
    -webkit-animation: band 9150ms linear infinite normal;
            animation: band 9150ms linear infinite normal;
}
.factoryContainer .stage ul {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 420px;
    height: 140px;
    overflow: hidden;
    -webkit-transform: translate(50px, 160px);
            transform: translate(50px, 160px);
}

.factoryContainer .stage ul:after {
    background: #415060;
    border-radius: 50%;
    width: 60px;
    height: 5px;
    bottom: 0px;
    right: 20px;
    z-index: 0;
}

.factoryContainer .stage ul li {
    overflow: hidden;
    z-index: 1;
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 0 0 0 0;
    background-color: transparent;
    -webkit-transform: translate(0px, 10px);
            transform: translate(0px, 10px);
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-animation-name: moveRight;
            animation-name: moveRight;
    -webkit-animation-duration: 6000ms;
            animation-duration: 6000ms;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.factoryContainer .stage ul li img {
    margin : 0;
}

.factoryContainer .stage ul li:before {
    content : "";
    display: none;
}

.factoryContainer .stage ul li:nth-child(1) {
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}

.factoryContainer .stage ul li:nth-child(2) {
  -webkit-animation-delay: 2000ms;
          animation-delay: 2000ms;
}

.factoryContainer .stage ul li:nth-child(3) {
  -webkit-animation-delay: 3000ms;
          animation-delay: 3000ms;
}

.factoryContainer .stage ul li:nth-child(4) {
  -webkit-animation-delay: 4000ms;
          animation-delay: 4000ms;
}

.factoryContainer .stage ul li:nth-child(5) {
  -webkit-animation-delay: 5000ms;
          animation-delay: 5000ms;
}

.factoryContainer .stage ul li:nth-child(6) {
  -webkit-animation-delay: 5000ms;
          animation-delay: 5000ms;
}

.factoryContainer .ps, .factoryContainer .factory::before, 
.factoryContainer .factory::after, .factoryContainer .magic::before, 
.factoryContainer .magic::after, .factoryContainer .screen::before, 
.factoryContainer .screen::after, .factoryContainer .band::before, 
.factoryContainer .band::after, .factoryContainer li::after {
  content: '';
  position: absolute;
}

@-webkit-keyframes moveRight {
  0% {
    -webkit-transform: translate(0px, 10px);
            transform: translate(0px, 10px);
  }
  80% {
    opacity : 1;
    -webkit-transform: translate(325px, 10px);
            transform: translate(325px, 10px);
  }
  90% {
    opacity : 1;
    -webkit-transform: translate(330px, 13px) rotateZ(90deg);
            transform: translate(330px, 13px) rotateZ(90deg);
  }
   95% {
         opacity : 0;
  }
  100% {
     opacity : 0;
    -webkit-transform: translate(340px, 110px) rotateZ(135deg);
            transform: translate(340px, 110px) rotateZ(135deg);
  }
}

@keyframes moveRight {
  0% {
    -webkit-transform: translate(0px, 10px);
            transform: translate(0px, 10px);
  }
  80% {
    opacity : 1;
    -webkit-transform: translate(325px, 10px);
            transform: translate(325px, 10px);
  }
  90% {
    opacity : 1;
    -webkit-transform: translate(330px, 13px) rotateZ(90deg);
            transform: translate(330px, 13px) rotateZ(90deg);
  }
   95% {
         opacity : 0;
  }
  100% {
     opacity : 0;
    -webkit-transform: translate(340px, 110px) rotateZ(135deg);
            transform: translate(340px, 110px) rotateZ(135deg);
  }
}
@-webkit-keyframes moveRightPseudo {
  0% {
    -webkit-transform: translate(-50px, 6px);
            transform: translate(-50px, 6px);
  }
  100% {
    -webkit-transform: translate(60px, 6px);
            transform: translate(60px, 6px);
  }
}
@keyframes moveRightPseudo {
  0% {
    -webkit-transform: translate(-50px, 6px);
            transform: translate(-50px, 6px);
  }
  100% {
    -webkit-transform: translate(60px, 6px);
            transform: translate(60px, 6px);
  }
}
@-webkit-keyframes upDown {
  0% {
    height: 70%;
  }
  67% {
    height: 0%;  
  }
  100% {
    height: 0%;
  }
}
@keyframes upDown {
  0% {
    height: 70%;
  }
  67% {
    height: 0%;  
  }
  100% {
    height: 0%;
  }
}
@-webkit-keyframes band {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}
@keyframes band {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}


.spinner-container {
    position: fixed;
    width: 100vw;
    height : 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index : 10;
    background-color: #fff;
    transition: all 0.5s ease-out;
}

.spinner-container > div { 
    transition: all 0.3s ease-in-out;
}

.spinner-hidden > div {
    opacity : 0;
}

.spinner-disappear {
    opacity : 0;
}

.spinner-none {
    display: none;
}

@-webkit-keyframes hide-spinner {
    0%    { opacity: 1; display : -webkit-flex; display : flex;}
    90%    { opacity: 0; background: transparent;}
    100%    { opacity: 0; display : none;}
}

@keyframes hide-spinner {
    0%    { opacity: 1; display : -webkit-flex; display : flex;}
    90%    { opacity: 0; background: transparent;}
    100%    { opacity: 0; display : none;}
}

@-webkit-keyframes ldio-ry3swy91qe8-o {
    0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
   49.99% { opacity: 1; -webkit-transform: translate(18.48px,0); transform: translate(18.48px,0) }
   50%    { opacity: 0; -webkit-transform: translate(18.48px,0); transform: translate(18.48px,0) }
  100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}

@keyframes ldio-ry3swy91qe8-o {
    0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
   49.99% { opacity: 1; -webkit-transform: translate(18.48px,0); transform: translate(18.48px,0) }
   50%    { opacity: 0; -webkit-transform: translate(18.48px,0); transform: translate(18.48px,0) }
  100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@-webkit-keyframes ldio-ry3swy91qe8 {
    0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
   50% { -webkit-transform: translate(18.48px,0); transform: translate(18.48px,0) }
  100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@keyframes ldio-ry3swy91qe8 {
    0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
   50% { -webkit-transform: translate(18.48px,0); transform: translate(18.48px,0) }
  100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}
.ldio-ry3swy91qe8 div {
  position: absolute;
  width: 18.48px;
  height: 18.48px;
  border-radius: 50%;
  top: 106.26px;
  left: 97.02px;
}
.ldio-ry3swy91qe8 div:nth-child(1) {
  background: #01425e;
  -webkit-animation: ldio-ry3swy91qe8 1s linear infinite;
          animation: ldio-ry3swy91qe8 1s linear infinite;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.ldio-ry3swy91qe8 div:nth-child(2) {
  background: #09bc8a;
  -webkit-animation: ldio-ry3swy91qe8 1s linear infinite;
          animation: ldio-ry3swy91qe8 1s linear infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.ldio-ry3swy91qe8 div:nth-child(3) {
  background: #01425e;
  -webkit-animation: ldio-ry3swy91qe8-o 1s linear infinite;
          animation: ldio-ry3swy91qe8-o 1s linear infinite;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-3g9rtblom18 {
  width: 231px;
  height: 231px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-ry3swy91qe8 {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio-ry3swy91qe8 div { box-sizing: content-box; }

