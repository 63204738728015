.factoryContainer {
   height : 340px;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: flex-end;
   padding : 2rem 0 4rem 0;
   max-width: 520px;
   margin : 0 auto;
   border : 1px solid #EAEAEA;
   border-radius: 10px;
}

.factory-selector{
    
    display : flex;
    flex-direction: row;
    position: absolute;
    top : 2rem;
    left : 50%;
    width: 380px;
    transform: translateX(-50%); 
}

.factory-selector-btn {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding : 0 1rem;
    width : 25%;
}

.factory-selector-btn > label {
    font-size : 1.4rem;
    padding : 0.5rem;
    text-align: center;
}

.factory-selector-btn > input {
    position: relative;
    margin : 0.5rem;
}

.factory-selector-btn img {
    max-width : 3.5rem;
    margin : 0;
}

.factoryContainer .stage {
    overflow: hidden;
    width: 500px;
    height: 230px;
    margin : 0 auto;
}

.factoryContainer .stage .factory {
    position: absolute;
    width: 75px;
    height: 65px;
    background: #EAEAEA;
    transform: translate(45px, 163px);
}

.factoryContainer .stage .factory::before {
    background: #d9d9d9;
    border-radius: 1px;
    width: 85px;
    height: 10px;
    top: -10px;
    left: -5px;
    -webkit-box-shadow: -4px 12px 21px -21px rgba(0,0,0,0.75);
    -moz-box-shadow: -4px 12px 21px -21px rgba(0,0,0,0.75);
    box-shadow: -4px 12px 21px -21px rgba(0,0,0,0.75);
}

.factoryContainer .stage .factory::after {
    width: 20px;
    height: 20px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    background-color: #c96160;
}

.factoryContainer .stage .magic {

    width: 40px;
    height: 45px;
    position: absolute;
    border-radius: 0 0 3px 3px;
    transform: translate(0, -60px);
    background: rgb(142,225,235);
    background: linear-gradient(90deg, rgba(142,225,235,0.3841911764705882) 31%, rgba(210,219,221,0.38139005602240894) 100%);
}
.factoryContainer .stage .magic::before {
    
    background: #cccccc;
    bottom: -5px;
    left: 20%;
    width: 60%;
    height: 5px;
}

.factoryContainer .magic::after {

    background: #56BA8E;
    border-radius: 0 0 3px 3px;
    bottom: 3px;
    left: 3px;
    width: 34px;
    height: 70%; 
    animation-name: upDown;
    animation-duration: 6000ms;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;  
}

.factoryContainer .stage .factory .arm::after {
    background: #F3D1C2;
    animation-duration : 6000ms;
}

.factoryContainer .stage .factory .wood::after {
    background: #8C5A40;
    animation-duration : 3000ms;
}

.factoryContainer .stage .factory .coal::after {
    background: #333538;
    animation-duration : 1333ms;
}

.factoryContainer .stage .factory .oil::after {
    background: #405D7A;
    animation-duration : 1000ms;
}

.factoryContainer .band {
    position: absolute;
    width: 350px;
    height: 15px;
    background: #364451;
    border-radius: 8px;
    transform: translate(50px, 210px);
}
.factoryContainer .band::before {
    background-image: radial-gradient(#4b5d6f 40%, transparent 41%);
    background-size: 15px 15px;
    background-position: 0px 0px;
    width: 90%;
    height: 15px;
    top: 0px;
    right: 5px;
    z-index: 2;
    animation: band 9150ms linear infinite normal;
}
.factoryContainer .stage ul {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 420px;
    height: 140px;
    overflow: hidden;
    transform: translate(50px, 160px);
}

.factoryContainer .stage ul:after {
    background: #415060;
    border-radius: 50%;
    width: 60px;
    height: 5px;
    bottom: 0px;
    right: 20px;
    z-index: 0;
}

.factoryContainer .stage ul li {
    overflow: hidden;
    z-index: 1;
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 0 0 0 0;
    background-color: transparent;
    transform: translate(0px, 10px);
    transform-origin: center bottom;
    animation-name: moveRight;
    animation-duration: 6000ms;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
}

.factoryContainer .stage ul li img {
    margin : 0;
}

.factoryContainer .stage ul li:before {
    content : "";
    display: none;
}

.factoryContainer .stage ul li:nth-child(1) {
  animation-delay: 1000ms;
}

.factoryContainer .stage ul li:nth-child(2) {
  animation-delay: 2000ms;
}

.factoryContainer .stage ul li:nth-child(3) {
  animation-delay: 3000ms;
}

.factoryContainer .stage ul li:nth-child(4) {
  animation-delay: 4000ms;
}

.factoryContainer .stage ul li:nth-child(5) {
  animation-delay: 5000ms;
}

.factoryContainer .stage ul li:nth-child(6) {
  animation-delay: 5000ms;
}

.factoryContainer .ps, .factoryContainer .factory::before, 
.factoryContainer .factory::after, .factoryContainer .magic::before, 
.factoryContainer .magic::after, .factoryContainer .screen::before, 
.factoryContainer .screen::after, .factoryContainer .band::before, 
.factoryContainer .band::after, .factoryContainer li::after {
  content: '';
  position: absolute;
}

@keyframes moveRight {
  0% {
    transform: translate(0px, 10px);
  }
  80% {
    opacity : 1;
    transform: translate(325px, 10px);
  }
  90% {
    opacity : 1;
    transform: translate(330px, 13px) rotateZ(90deg);
  }
   95% {
         opacity : 0;
  }
  100% {
     opacity : 0;
    transform: translate(340px, 110px) rotateZ(135deg);
  }
}
@keyframes moveRightPseudo {
  0% {
    transform: translate(-50px, 6px);
  }
  100% {
    transform: translate(60px, 6px);
  }
}
@keyframes upDown {
  0% {
    height: 70%;
  }
  67% {
    height: 0%;  
  }
  100% {
    height: 0%;
  }
}
@keyframes band {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}
