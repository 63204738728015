.water-cycle-container .sun {
    
  display: block;
  width : auto; 
  height: 60px;
  margin : 0;
  position: absolute;
  right: 10%;
  top: 0;
}

.water-cycle-container {
  width: 420px;
  position: relative;
  margin : auto;
}

#Arrows {
  opacity: 0.2;
}
#Arrows #a_evaporation path {
  animation: a_evap 0.8s infinite alternate ease-in-out;
}
#Arrows #a_transpiration {
  display: none;
  animation: a_evap 0.8s infinite     alternate ease-in-out;
}
#Arrows #a_condensation {
  animation: a_evap 0.8s infinite     alternate ease-in-out;
}
#Arrows #a_precipitation {
  display: none;
  animation: a_evap 0.8s infinite       alternate ease-in-out;
}
#Arrows #a_runoff {
  display: none;
  animation: a_evap 0.8s infinite       alternate ease-in-out;
}
#Arrows #a_perculation {
  display: none;
  animation: a_evap 0.8s infinite      alternate ease-in-out;
}

.prefix__wave {
  animation: wave 4s infinite alternate ease-in-out;
}

#rain path {
  animation: rain 2s infinite alternate linear;
}
#rain path:nth-child(1) {
  animation-delay: 0.0s;
}
#rain path:nth-child(2) {
  animation-delay: 1.7s;
}
#rain path:nth-child(3) {
  animation-delay: 0.2s;
}
#rain path:nth-child(4) {
  animation-delay: 2.1s;
}
#rain path:nth-child(5) {
  animation-delay: 0.7s;
}
#rain path:nth-child(6) {
  animation-delay: 1.2s;
}
#rain path:nth-child(7) {
  animation-delay: 0.1s;
}
#rain path:nth-child(8) {
  animation-delay: 1.5s;
}

#clouds path {
  animation: clouds 8.5s infinite linear;
}
#clouds path:nth-child(1) {
  animation-delay: 0s;
}
#clouds path:nth-child(2) {
  animation-delay: -4s;
}

.atom {
  height: 25px;
  top: 4.8%;
  right: 14.2%;
}

@keyframes rain {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.2;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes wave {
  0% {
    opacity: 0.5;
    transform: translate(20px, 10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    transform: translate(-40px, -20px);
  }
}
@keyframes a_evap {
  0% {
    opacity: 0.2;
  }
  65% {
    opacity: 1;
  }
}
@keyframes clouds {
  0% {
    opacity: 1;
    transform: translate(-20px, 0px);
  }
  25% {
    opacity: 1;
    transform: translate(0px, 20px);
  }
  50% {
    opacity: 1;
    transform: translate(20px, 10px);
  }
  75% {
    opacity: 1;
    transform: translate(0px, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(-20px, 0px);
  }
}
.blinking {
  animation: blinkingAnim 1.5s infinite;
}

@keyframes blinkingAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
