
.spinner-container {
    position: fixed;
    width: 100vw;
    height : 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index : 10;
    background-color: #fff;
    transition: all 0.5s ease-out;
}

.spinner-container > div { 
    transition: all 0.3s ease-in-out;
}

.spinner-hidden > div {
    opacity : 0;
}

.spinner-disappear {
    opacity : 0;
}

.spinner-none {
    display: none;
}

@keyframes hide-spinner {
    0%    { opacity: 1; display : flex;}
    90%    { opacity: 0; background: transparent;}
    100%    { opacity: 0; display : none;}
}

@keyframes ldio-ry3swy91qe8-o {
    0%    { opacity: 1; transform: translate(0 0) }
   49.99% { opacity: 1; transform: translate(18.48px,0) }
   50%    { opacity: 0; transform: translate(18.48px,0) }
  100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-ry3swy91qe8 {
    0% { transform: translate(0,0) }
   50% { transform: translate(18.48px,0) }
  100% { transform: translate(0,0) }
}
.ldio-ry3swy91qe8 div {
  position: absolute;
  width: 18.48px;
  height: 18.48px;
  border-radius: 50%;
  top: 106.26px;
  left: 97.02px;
}
.ldio-ry3swy91qe8 div:nth-child(1) {
  background: #01425e;
  animation: ldio-ry3swy91qe8 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-ry3swy91qe8 div:nth-child(2) {
  background: #09bc8a;
  animation: ldio-ry3swy91qe8 1s linear infinite;
  animation-delay: 0s;
}
.ldio-ry3swy91qe8 div:nth-child(3) {
  background: #01425e;
  animation: ldio-ry3swy91qe8-o 1s linear infinite;
  animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-3g9rtblom18 {
  width: 231px;
  height: 231px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-ry3swy91qe8 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ry3swy91qe8 div { box-sizing: content-box; }
