div.arrow-icon {
	display: inline-block;
	vertical-align: middle;
}

a {
	display: inline-block;
	border-radius: 50%;
}

a:hover .nav-arrov-right {
	background-color: #00a77b;
}

.nav-arrov-right,
.check {
	display: inline-block;
	width: 4rem;
	height: 4rem;
	background-color: #09bc8a;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.nav-arrov-right:hover {
	background-color: #00a77b;
}

.nav-arrov-right:hover:after {
	margin-left: 1.2rem;
}

.check:after {
	content: '';
	display: inline-block;
	margin-top: 1.1rem;
	margin-left: 0.9rem;
	width: 1.9rem;
	height: 1.2rem;
	border-top: 0.2rem solid #fff;
	border-right: 0.2rem solid #fff;
	-moz-transform: rotate(130deg);
	-webkit-transform: rotate(130deg);
	transform: rotate(130deg);
	transition: all 0.3s ease-in-out;
}

.nav-arrov-right:after {
	content: '';
	display: inline-block;
	margin-top: 1.1rem;
	margin-left: 0.9rem;
	width: 1.3rem;
	height: 1.2rem;
	border-top: 0.2rem solid #fff;
	border-right: 0.2rem solid #fff;
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	transition: all 0.3s ease-in-out;
}

.left {
	display: inline-block;
	width: 4em;
	height: 4em;
	border-radius: 50%;
	margin-right: 1.5em;
}

.left:after {
	content: '';
	display: inline-block;
	margin-top: 1.05em;
	margin-left: 0.6em;
	width: 1.4em;
	height: 1.4em;
	border-top: 0.3rem solid #333;
	border-right: 0.3rem solid #333;
	-moz-transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.top {
	display: inline-block;
	width: 4em;
	height: 4em;
	border: 0.5em solid #333;
	border-radius: 50%;
	margin-right: 0.75em;
}

.top:after {
	content: '';
	display: inline-block;
	margin-top: 1.4em;
	width: 1.4em;
	height: 1.4em;
	border-top: 0.5em solid #333;
	border-right: 0.5em solid #333;
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.bottom {
	display: inline-block;
	width: 4em;
	height: 4em;
	border: 0.5em solid #333;
	border-radius: 50%;
	margin-left: 0.75em;
}

.bottom:after {
	content: '';
	display: inline-block;
	margin-top: 0.6em;
	width: 1.4em;
	height: 1.4em;
	border-top: 0.5em solid #333;
	border-right: 0.5em solid #333;
	-moz-transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}
